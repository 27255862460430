import React from 'react';
import '../Main/css/ServicePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faHeadset, faMusic } from '@fortawesome/free-solid-svg-icons';

const ServicePage = () => {
  return (
    <div>
      <h2 className='service-title'>My Services</h2>
      <div className="service-section">
        <div className="service-card">
          <FontAwesomeIcon icon={faMicrophone} className="service-icon" />
          <h3>Voice Recording</h3>
          <p>
            I offer professional voice recording services to bring your vocal projects to life. Whether you need voice-over work, podcast recording, or vocal production, I'll ensure clear and high-quality recordings.
          </p>
        </div>
        <div className="service-card">
          <FontAwesomeIcon icon={faHeadset} className="service-icon" />
          <h3>Audio Mixing and Mastering</h3>
          <p>
            Ensure your tracks sound polished and professional with my audio mixing and mastering services. I'll balance the elements of your audio and apply the final touches to achieve a clear, impactful, and radio-ready sound.
          </p>
        </div>
        <div className="service-card">
        <FontAwesomeIcon icon={faMusic} className="service-icon" />
          <h3>Sound Design</h3>
          <p>
            Let your projects stand out with unique and captivating sound design. I'll create custom sounds and textures to add depth and character, ensuring they resonate with your audience.
          </p>
        </div>
        {/* Add more service cards as needed */}
      </div>
    </div>
  );
};

export default ServicePage;
