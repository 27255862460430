import React from 'react';

function ContactPage() {
  return (
    <div>
      <h1>Contact</h1>
      <p></p>
      {/* Add a contact form or contact details here */}
    </div>
  );
}

export default ContactPage;
