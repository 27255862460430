import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from '../src/Nav/Navbar';
import HomePage from './Main/HomePage';
import MusicPage from './MusicPage';
import ContactPage from './ContactPage';
import LoginPage from './LoginPage';
import AboutMe from './Main/AboutMe';
import ServicePage from './Main/ServicePage';

function App() {
  return (
    <Router>
      <Navbar /> {/* Add the Navbar component */}
      <Routes>
        <Route path="/" element={
          <>
            <HomePage />
            <AboutMe />
            <ServicePage />
          </>
        } />
        <Route path="/music" element={<MusicPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;