import React from 'react';
import './css/HomePage.css';

function HomePage() {
  return (
    <div className="home-page">
      <div className="home-content">
        <h1>Edgar Rogriguez</h1>
        <p>Music Producer</p>
      </div>
    </div>
  );
}

export default HomePage;
