import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="desktop-menu">
        <ul>
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <Link to="/music">MUSIC</Link>
          </li>
          <li>
            <Link to="/contact">CONTACT</Link>
          </li>
          |
          <li>
            <Link to="/login">LOGIN</Link>
          </li>
        </ul>
      </div>
      <div className="mobile-menu">
        <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <ul className={`menu ${isMenuOpen ? 'open' : ''}`}>
          <li>
            <Link to="/" onClick={handleMenuToggle}>
              HOME
            </Link>
          </li>
          <li>
            <Link to="/music" onClick={handleMenuToggle}>
              MUSIC
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={handleMenuToggle}>
              CONTACT
            </Link>
          </li>
          <li>
            <Link to="/login" onClick={handleMenuToggle}>
              LOGIN
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
