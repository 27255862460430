import React from 'react';
import './css/AboutMe.css'; // Don't forget to create the CSS file to style the component

const AboutMe = () => {
  return (
    <div className="about-me-container">
       <div className="about-me-text">
       <h2>About Me</h2>
        <p>
          Hello! I'm Edgar Rodriguez, a passionate musician and songwriter from Yellow Room Music.
          I've been deeply involved in the music industry for many years, and my love for music drives
          me to create soulful melodies and meaningful lyrics.
        </p>
        <p>
          My musical journey started at a young age when I picked up my first guitar, and since then,
          I've never looked back. I find inspiration in various genres and use that to craft my own unique sound.
        </p>
        <p>
          At Yellow Room Music, we believe in the power of music to connect with people on a profound level,
          and it's my goal to touch hearts and minds through my songs. Whether it's performing live on stage
          or recording in the studio, every moment with music is magical, and I'm thrilled to share that magic
          with all of you.
        </p>
        <p>
          Thank you for being a part of this musical journey. Stay tuned for new releases, upcoming shows,
          and exciting collaborations!
        </p>
      </div>
      <div className="about-me-image">
        <img src={process.env.PUBLIC_URL + "/imgs/edgar-2.jpg"} alt="Edgar Rodriguez" />
        
      </div>
    </div>
  );
};

export default AboutMe;
